<template>
  <main>
    <!-- application form account-info change -->
    <section id="register_points_search_payment">
      <div class="w1300 layout_container">

        <!-- 表單主標題 -->
        <div class="main_title_box">
          <h3 class="main_title txt-bold">{{ orderType }}繳費</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <!-- table area -->
        <div class="table_box">
          <table class="txt-center">
            <thead>
              <tr>
                <th class="td-main txt-left"><p>{{ th1 }}</p></th>
                <th class="min180"><p>{{ th2 }}</p></th>
                <th class="min100"><p>{{ th3 }}</p></th>
              </tr>
            </thead>
            <tbody id="table_data">

              <tr v-for="item in orderList" :key="item.uuid">
                <td :data-title="th1" class="td-main txt-left"><p>{{ item.content }}</p></td>
                <td :data-title="th2" class="td-input">
                  <input
                    type="text"
                    placeholder="請輸入收據抬頭"
                    v-model="item.title"
                  >
                </td>
                <td :data-title="th3"><p>{{ item.price }}</p></td>
              </tr>

            </tbody>
          </table>
        </div>

        <div class="payment_check txt-right cf active">
          <p class="payment_check_total">
            總金額 <span class="txt-light_green">{{ orderAmount }}</span> 元
          </p>
          <div class="filter_box payment_check_container fright">
            <SelectPayment
              id="pay_type"
              v-model="formData.pay_id"
              :item="inputFormat.paymentSelect"
              :rules="[
                val => !!val || '必選欄位！',
              ]"
            />

            <!-- 信用卡繳費：綠界金流 -->
            <button
              type="button"
              class="btn p"
              @click="onSubmit"
            >前往繳費</button>
          </div>
        </div>

      </div>
    </section>
  </main>
</template>
<script>
/** Libraray */
import _ from 'lodash';

/** Components */
import SelectPayment from '@/components/form/SelectPayment.vue';

/** Functions */
import { mapActions } from 'vuex';
import { orderView, userInfo } from '@/lib/http';
import { routeConverter } from '@/lib/public';

export default {
  name: 'Payment',
  data() {
    return {
      /**
       * Static Data
       */
      orderType: '重新',
      th1: '費用名稱',
      th2: '收據抬頭',
      th3: '金額',
      orderID: '',
      orderInfo: {},
      inputFormat: {
        paymentSelect: {
          placeHolder: '請選擇繳費方式',
          options: [
            {
              name: '信用卡',
              value: 1,
            },
            // {
            //   name: 'ATM轉帳',
            //   value: 2,
            // },
            // {
            //   name: '超商代碼繳費',
            //   value: 3,
            // },
          ],
        },
      },
      /**
       * Form Data
       */
      feeTitle: '',
      formData: {
        order_uuid: '',
        pay_id: 1,
        detail: [],
        return_url: '',
      },
    };
  },
  created() {
    if (this.$route.query && this.$route.query.order_id) {
      if (routeConverter(this.$route.path)) {
        this.orderType = routeConverter(this.$route.path);
      }
      if (this.orderType === '活動紀錄') {
        this.formData.return_url = '/register/record/success';
      } else if (this.orderType === '費用查詢') {
        this.formData.return_url = '/account/search/success';
      }
      userInfo().then((result) => {
        this.feeTitle = _.get(result, 'memo.fee_title', '');
      }).then(() => {
        this.orderID = this.$route.query.order_id;
        orderView({ uuid: this.orderID }).then((result) => {
          const target = _.get(result, 'data.result.data', {});
          if (Object.keys(target).length > 0) {
            target.order_details.forEach((item) => {
              // eslint-disable-next-line no-param-reassign
              item.title = this.feeTitle;
            });
          }
          this.orderInfo = target;
          this.formData.order_uuid = _.get(result, 'data.result.data.uuid', '');
        });
      });
    } else {
      this.openModal({
        url: this.redirectURL,
        message: this.redirectMsg,
      });
    }
  },
  computed: {
    orderList() {
      return _.get(this.orderInfo, 'order_details');
    },
    orderAmount() {
      return _.get(this.orderInfo, 'total', '');
    },
    redirectURL() {
      let target = '';
      switch (this.orderType) {
        case '活動紀錄':
          target = '/register/record';
          break;
        case '費用查詢':
          target = '/account/search/fee';
          break;
        default:
          break;
      }
      return target;
    },
    redirectMsg() {
      let target = '';
      switch (this.orderType) {
        case '活動紀錄':
          target = '請返回活動紀錄選擇欲繳費項目';
          break;
        case '費用查詢':
          target = '請返回會員費用查詢選擇欲繳費項目';
          break;
        default:
          break;
      }
      return target;
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        this.orderList.forEach((item) => {
          this.formData.detail.push({
            uuid: item.uuid,
            title: item.title,
          });
        });
        // console.log('form data', this.formData);
        this.$cookies.set('orderPaymentInfo', JSON.stringify(this.formData));
        if (this.orderType === '活動紀錄') {
          this.$router.push({ path: `/register/record/confirm?order_id=${this.orderID}` });
        } else if (this.orderType === '費用查詢') {
          this.$router.push({ path: `/account/search/confirm?order_id=${this.orderID}` });
        }
      } else {
        this.openModal({
          message: '必填欄位未完成',
        });
      }
    },
  },
  components: {
    SelectPayment,
  },
};
</script>
